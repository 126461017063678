import styled from 'styled-components'

export const Pagi = styled.div`
  width: 100%;
  padding: 4px 0 24px;
  .ant-pagination {
    zoom: 0.7;
    display: flex;
    justify-content: center;
    gap: 2px;
  }
  /* .ant-pagination-next,
  .ant-pagination-prev, */
  .ant-pagination-options {
    display: none;
  }
  .ant-pagination-item {
    border: 1px solid #dddddd !important;
    a {
      font-family: 'NotoSansKr-Regular' !important;
      color: #007bff !important;
      /* color: #ffffff;÷\ */
      padding: 0 2px;
      margin: 0 1px;
    }
  }
  .ant-pagination-item-active {
    background-color: #007bff !important;
    border-color: #007bff !important;
    a {
      font-family: 'NotoSansKr-Bold' !important;
      color: #fff !important;
      background: none !important;
    }
  }
  .ant-pagination-jump-prev,
  .ant-pagination-jump-next {
    .ant-pagination-item-link-icon {
      color: #c5c5c590 !important;
    }
    .ant-pagination-item-ellipsis {
      color: #c5c5c590 !important;
      margin-top: 4px !important;
    }
  }
  .ant-pagination-prev,
  .ant-pagination-next {
    border: 1px solid #dddddd !important;
    margin: 0;
    svg {
      color: #007bff;
    }
  }
`
