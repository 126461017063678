import React, { useEffect, useState } from 'react' //lazy

import { Layout } from 'antd'
import { UrlInternal } from 'common/constants/endpoints'
// import { ArrowUpOutlined } from "@ant-design/icons";

// import { UrlInternal } from "common/constants/endpoints";
import Item from './item'

import { miniAllApi } from 'apis'

import * as S from './style'

interface Props {
  onClick?: any
  className?: any
}

const ListGame = (props: Props) => {
  const { onClick, className } = props

  const [dataGame, setDataGame] = useState<any>([])
  const [lock, setLock] = useState<boolean>(true)

  const getMiniGame = async () => {
    try {
      const res: any = await miniAllApi()
      // console.log(res);
      if (res.result === false) {
        alert(res.message)
      } else {
        setDataGame(res)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getMiniGame()
  }, [])

  useEffect(() => {
    const interval = setInterval(() => {
      getMiniGame()
    }, 30000)
    return () => clearInterval(interval)
  }, [])

  return (
    <S.Wrapper>
      <S.Content>
        {dataGame?.map((v: any, i: number) => (
          <div key={i}>
            <Item data={v} />
          </div>
        ))}
      </S.Content>
    </S.Wrapper>
  )
}

export default ListGame
