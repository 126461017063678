import { mainAxios } from 'libs/axios'

interface ListPartnerProps {
  limit?: string | number
  page?: string | number
  keyfield: string
  key: string
}

export const getListPartner = (payload: ListPartnerProps) => {
  return mainAxios.request({
    methodType: 'GET',
    url: `/partner`,
    params: payload,
    requiresToken: true,
    notError: true,
    getError: true,
    config: {
      headers: {
        contentType: 'application/json'
      }
    }
  })
}

export const getLevelPartner = () => {
  return mainAxios.request({
    methodType: 'GET',
    url: `/partner/member-level`,
    // params: payload,
    requiresToken: true,
    notError: true,
    getError: true,
    config: {
      headers: {
        contentType: 'application/json'
      }
    }
  })
}

interface PartnerAddChildProps {
  id?: string | number
  mb_id: string
  mb_name: string
  mb_password: string
  mb_hp: string
  mb_bank_name: string
  mb_bank_no: string
  mb_bank_own: string
  mb_exch_pw: string
}

export const getPartnerAddChild = (payload: PartnerAddChildProps) => {
  const { id, ...rest } = payload
  return mainAxios.request({
    methodType: 'Post',
    url: `/partner/input/${payload.id}`,
    payload: rest,
    requiresToken: true,
    notError: true,
    getError: true,
    config: {
      headers: {
        contentType: 'application/json'
      }
    }
  })
}

interface PartnerChildProps {
  id?: string | number
  keyfield?: string
  key?: string
  page?: string | number
}

export const getPartnerChild = (payload: PartnerChildProps) => {
  const { id, ...rest } = payload
  return mainAxios.request({
    methodType: 'GET',
    url: `/partner/child/${payload.id}`,
    params: rest,
    requiresToken: true,
    notError: true,
    getError: true,
    config: {
      headers: {
        contentType: 'application/json'
      }
    }
  })
}

interface PartnerDepositRankingProps {
  id?: string | number
  sdate?: string
  edate?: string
}

export const getPartnerDepositRanking = (
  payload: PartnerDepositRankingProps
) => {
  const { id, ...rest } = payload
  return mainAxios.request({
    methodType: 'GET',
    url: `/partner/ranking/deposit/${payload.id}`,
    params: rest,
    requiresToken: true,
    notError: true,
    getError: true,
    config: {
      headers: {
        contentType: 'application/json'
      }
    }
  })
}

interface PartnerWithdrawRankingProps {
  id?: string | number
  sdate?: string
  edate?: string
}

export const getPartnerWithdrawRanking = (
  payload: PartnerWithdrawRankingProps
) => {
  const { id, ...rest } = payload
  return mainAxios.request({
    methodType: 'GET',
    url: `/partner/ranking/withdraw/${payload.id}`,
    params: rest,
    requiresToken: true,
    notError: true,
    getError: true,
    config: {
      headers: {
        contentType: 'application/json'
      }
    }
  })
}

interface PartnerRollingRankingProps {
  id?: string | number
  game_name: string
  game_sub: string
  sdate?: string
  edate?: string
}

export const getPartnerRollingRanking = (
  payload: PartnerRollingRankingProps
) => {
  const { id, ...rest } = payload
  return mainAxios.request({
    methodType: 'GET',
    url: `/partner/ranking/rolling/${payload.id}`,
    params: rest,
    requiresToken: true,
    notError: true,
    getError: true,
    config: {
      headers: {
        contentType: 'application/json'
      }
    }
  })
}

interface PartnerBettingRankingProps {
  id?: string | number
  game_name: string
  game_sub: string
  sdate?: string
  edate?: string
}

export const getPartnerBettingRanking = (
  payload: PartnerBettingRankingProps
) => {
  const { id, ...rest } = payload
  return mainAxios.request({
    methodType: 'GET',
    url: `/partner/ranking/betting/${payload.id}`,
    params: rest,
    requiresToken: true,
    notError: true,
    getError: true,
    config: {
      headers: {
        contentType: 'application/json'
      }
    }
  })
}

interface PartnerBettingRankingDetailProps {
  id?: string | number
  gamesel: string
  game_type: string
  game_group: string
  sdate?: string
  edate?: string
}

export const getPartnerBettingRankingDetail = (
  payload: PartnerBettingRankingDetailProps
) => {
  return mainAxios.request({
    methodType: 'GET',
    url: `/partner/ranking/betting/detail`,
    params: payload,
    requiresToken: true,
    notError: true,
    getError: true,
    config: {
      headers: {
        contentType: 'application/json'
      }
    }
  })
}

interface PartnerRateProps {
  id?: string | number
}

export const getPartnerRate = (payload: PartnerRateProps) => {
  return mainAxios.request({
    methodType: 'GET',
    url: `/partner/rate/${payload.id}`,
    // params: payload,
    requiresToken: true,
    notError: true,
    getError: true,
    config: {
      headers: {
        contentType: 'application/json'
      }
    }
  })
}

interface PartnerRateUpdateProps {
  id?: string | number
}

export const getPartnerRateUpdate = (payload: PartnerRateUpdateProps) => {
  const { id, ...rest } = payload
  return mainAxios.request({
    methodType: 'POST',
    url: `/partner/rate/modify/${payload.id}`,
    payload: rest,
    requiresToken: true,
    notError: true,
    getError: true,
    config: {
      headers: {
        contentType: 'application/json'
      }
    }
  })
}

interface PartnerDepositListProps {
  id?: string | number
  sdate?: string
  edate?: string
  page?: string | number
}

export const getPartnerDepositList = (payload: PartnerDepositListProps) => {
  const { id, ...rest } = payload
  return mainAxios.request({
    methodType: 'GET',
    url: `/partner/deposit/${payload.id}`,
    params: rest,
    requiresToken: true,
    notError: true,
    getError: true,
    config: {
      headers: {
        contentType: 'application/json'
      }
    }
  })
}

interface PartnerDepositPartnerListProps {
  id?: string | number
  sdate?: string
  page?: string | number
}

export const getPartnerDepositPartnerList = (
  payload: PartnerDepositPartnerListProps
) => {
  const { id, ...rest } = payload
  return mainAxios.request({
    methodType: 'GET',
    url: `/partner/partner/deposit/${payload.id}`,
    params: rest,
    requiresToken: true,
    notError: true,
    getError: true,
    config: {
      headers: {
        contentType: 'application/json'
      }
    }
  })
}

interface PartnerDepositListAllProps {
  sdate?: string
  page?: string | number
}

export const getPartnerDepositListAll = (
  payload: PartnerDepositListAllProps
) => {
  return mainAxios.request({
    methodType: 'GET',
    url: `/partner/partner/deposit/all`,
    params: payload,
    requiresToken: true,
    notError: true,
    getError: true,
    config: {
      headers: {
        contentType: 'application/json'
      }
    }
  })
}

interface PartnerWithdrawListProps {
  id?: string | number
  sdate?: string
  edate?: string
  page?: number | string
}

export const getPartnerWithdrawList = (payload: PartnerWithdrawListProps) => {
  return mainAxios.request({
    methodType: 'GET',
    url: `/partner/withdraw/${payload.id}`,
    params: { sdate: payload.sdate, edate: payload.edate },
    requiresToken: true,
    notError: true,
    getError: true,
    config: {
      headers: {
        contentType: 'application/json'
      }
    }
  })
}

interface PartnerWithdrawPartnerListProps {
  id?: string | number
  sdate?: string
  page?: number | string
}

export const getPartnerWithdrawPartnerList = (
  payload: PartnerWithdrawPartnerListProps
) => {
  const { id, ...rest } = payload
  return mainAxios.request({
    methodType: 'GET',
    url: `/partner/partner/withdraw/${payload.id}`,
    params: rest,
    requiresToken: true,
    notError: true,
    getError: true,
    config: {
      headers: {
        contentType: 'application/json'
      }
    }
  })
}

interface PartnerWithdrawListAllProps {
  sdate?: string
  page?: number | string
}

export const getPartnerWithdrawListAll = (
  payload: PartnerWithdrawListAllProps
) => {
  return mainAxios.request({
    methodType: 'GET',
    url: `/partner/partner/withdraw/all`,
    params: payload,
    requiresToken: true,
    notError: true,
    getError: true,
    config: {
      headers: {
        contentType: 'application/json'
      }
    }
  })
}

interface PartnerBettingListProps {
  id?: string | number
  sdate?: string
  game_name?: string
  game_sub?: string
  t?: string
  page: number | string
}

export const getPartnerBettingList = (payload: PartnerBettingListProps) => {
  const { id, ...rest } = payload
  return mainAxios.request({
    methodType: 'GET',
    url: `/partner/betting/${payload.id}`,
    params: rest,
    requiresToken: true,
    notError: true,
    getError: true,
    config: {
      headers: {
        contentType: 'application/json'
      }
    }
  })
}

interface PartnerRollingListProps {
  id?: string | number
  sdate?: string
  game_name?: string
  game_sub?: string
  page?: string | number
}

export const getPartnerRollingList = (payload: PartnerRollingListProps) => {
  const { id, ...rest } = payload
  return mainAxios.request({
    methodType: 'GET',
    url: `/partner/rolling/${payload.id}`,
    params: rest,
    requiresToken: true,
    notError: true,
    getError: true,
    config: {
      headers: {
        contentType: 'application/json'
      }
    }
  })
}

interface PartnerDepositMoneyProps {
  id?: string | number
  add_money?: string | number
}

export const postPartnerDepositMoney = (payload: PartnerDepositMoneyProps) => {
  return mainAxios.request({
    methodType: 'POST',
    url: `/partner/money/deposit/${payload.id}`,
    payload: {
      add_money: payload.add_money
    },
    requiresToken: true,
    notError: true,
    getError: true,
    config: {
      headers: {
        contentType: 'application/json'
      }
    }
  })
}

interface PartnerWithdrawMoneyProps {
  id?: string | number
  out_money?: string | number
}

export const postPartnerWithdrawMoney = (
  payload: PartnerWithdrawMoneyProps
) => {
  return mainAxios.request({
    methodType: 'POST',
    url: `/partner/money/withdraw/${payload.id}`,
    payload: {
      out_money: payload.out_money
    },
    requiresToken: true,
    notError: true,
    getError: true,
    config: {
      headers: {
        contentType: 'application/json'
      }
    }
  })
}
