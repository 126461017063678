import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

export default function ScrollToTop() {
  const { pathname } = useLocation()

  useEffect(() => {
    window.onbeforeunload = function () {
      window.scrollTo(0, 0)
    }
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  useEffect(() => {
    window.onbeforeunload = function () {
      window.scrollTo(0, 0)
    }
  }, [])

  return null
}

export const clickToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  })
}
