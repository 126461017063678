import styled from 'styled-components'

export const Wrapper = styled.button`
  width: 100%;
  border-radius: 4px;
  color: #fff;
  font-size: 15px;
  font-weight: 500;
  padding: 10px 0;
  margin-bottom: 0;
  margin-right: 0;
  border: 1px solid #281a0b;
  background: linear-gradient(
    to bottom,
    #9b3e09 0%,
    #7c0000 36%,
    #4a0502 65%,
    #3a0901 100%,
    #542c02 100%,
    #9b3e09 100%
  );

  &:hover {
    background: linear-gradient(to top, #ff5e00, #ffe400);
  }
`
