import types from '../actions/types'

const defaultState = {
  isWaitting: false
}

export default (state = defaultState, action: any) => {
  const type = action?.type

  switch (type) {
    case types.WAITTING:
      return { isWaitting: true }
    case types.STOP_WAITTING:
      return { isWaitting: false }
    default:
      return state
  }
}
