import styled from 'styled-components'
import theme from 'common/style/styleSetup'

export const Wrapper = styled.div`
  width: 100%;
  margin-bottom: 4px;
`

export const BoxMoney = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .item {
    cursor: pointer;
    width: calc(50% - 2px);
    height: 40px;
    background: linear-gradient(#e25b38, #b03413);
    color: ${theme.color[0]};
    border-radius: 2px;
    font-size: 12px;
    font-weight: 600;

    display: flex;
    justify-content: center;
    align-items: center;
    .fa {
      margin-right: 6px;
    }
    :hover {
      background: linear-gradient(#b12b08, #821d03);
    }
  }
`

export const LinkBox = styled.div`
  width: 100%;
  height: auto;
  /* margin-left: 2px; */
  /* padding: 1px; */
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-start;
  /* gap:1px  2px; */
  .item-link {
    margin: 1px 1px;
    /* margin-left: 2px; */
    cursor: pointer;
    width: calc(33.33% - 2px);
    height: 40px;
    background: ${theme.bg[19]};
    color: ${theme.color[0]};
    border-radius: 2px;
    font-size: 11px;

    display: flex;
    justify-content: center;
    align-items: center;
    :hover {
      background-color: #487eab;
    }
  }
`

export const SubTitle = styled.p`
  font-family: NotoSansKR-Light;
  font-size: 16px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.4px;
  text-align: center;
  color: #dbd5d5;
  br {
    display: none;
  }
  @media (max-width: 992px) {
    width: 234px;
    margin-left: auto;
    margin-right: auto;
    br {
      display: unset;
    }
  }
`

export const Content = styled.div`
  border-top: solid 1px #3d3d3d;
  border-bottom: solid 1px #3d3d3d;
  display: flex;
  justify-content: space-between;
  max-width: 1400px;
  padding: 60px 40px;
  align-items: center;
  margin: 0 auto;
  width: 100%;
  flex-wrap: wrap;
  .wrapper {
    display: flex;
    align-items: top;
    justify-content: space-between;
    width: 80%;
    gap: 10px;
  }
  @media (max-width: 1100px) {
    flex-direction: column;
    row-gap: 35px;
    .wrapper {
      width: 100%;
    }
  }
  @media (max-width: 992px) {
    .wrapper {
      flex-wrap: wrap;
      gap: 50px 0px;
      .children {
        flex: 0 0 50%;
      }
    }
  }
  @media (max-width: 478px) {
    .wrapper {
      .children {
        flex: 0 0 100%;
      }
    }
  }
`

export const TextContent = styled.p`
  margin: 0 0 20px;
  font-family: NotoSansKR-Light;
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: 0.35px;
  text-align: left;
  color: #ebebeb;
  @media (max-width: 992px) {
    text-align: center;
    margin-bottom: 6px;
  }
`

export const Description = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.46;
  letter-spacing: 0.33px;
  text-align: left;
  color: #a2a2a2;
  span {
    font-size: 24px;
  }
  .mobile {
    display: none;
  }
  @media (max-width: 992px) {
    text-align: center;
    .desktop {
      display: none;
    }
    .mobile {
      display: unset;
    }
  }
`

export const Link = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.46;
  letter-spacing: 0.33px;
  text-align: left;
  color: #a2a2a2;
  margin: 0;
  cursor: pointer;

  @media (max-width: 992px) {
    text-align: center;
  }
`
