import { Button } from 'antd'
import CloseIcon from 'assets/icons/close.svg'
import styled from 'styled-components'

export const SearchBox = styled.div`
  background-color: #ffffff;
  position: absolute;
  z-index: 99;
  width: 100%;
  left: 0;
  top: 0;
  opacity: 0;

  &.open {
    opacity: 1;
    visibility: visible;
  }
`

export const SearchBoxOverlay = styled.div`
  opacity: 0;
  width: 100%;
  height: 100%;
  visibility: hidden;
  background-color: rgb(0, 0, 0, 0.8);
  opacity: 0;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 50;
  cursor: url(${CloseIcon}), auto;
  pointer-events: auto;
  /* color: var(--gt-overlay-close-icon-color); */
  -webkit-transition: 0.2s;
  -o-transition: 0.2s;
  transition: 0.2s;
  &.open {
    opacity: 0.5;
    visibility: visible;
  }
`

export const SearchBoxContainer = styled.div`
  width: 100%;
  height: 500px;
  background-color: #ffffff;
  position: fixed;
  z-index: 99;
  padding: 180px 0;
  left: 0;
  top: 0;
  -webkit-transform: translateY(-200%);
  -ms-transform: translateY(-200%);
  transform: translateY(-200%);
  padding: 0px 206.5px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s;

  &.show {
    opacity: 1;
    visibility: visible;
    transform: translate(0px, 0px);
  }

  @media screen and (max-width: 768px) {
    height: 346px;
    padding: 0px 20px;
  }
`

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 20.5px;
  border-bottom: solid 1px #dedede;
  opacity: 0;
  transition: all 1s;
  transform: translateY(200%);
  &.show {
    opacity: 1;
    transform: translate(0px, 0px);
  }

  /* @media screen and (max-width: 768px) {
    padding-bottom: 28.5px;
  } */
`

export const SearchInput = styled.input`
  width: 100%;
  border: none;
  outline: none;
  font-size: 45px;
  font-family: 'NotoSansKR-Light';

  &:active,
  :focus {
    outline: none;
  }

  &::placeholder {
    font-size: 45px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 1.13px;
    text-align: left;
    color: #929292;
  }

  @media screen and (max-width: 768px) {
    font-size: 20px;
    &::placeholder {
      font-size: 20px;
    }
  }
`

export const SearchButton = styled(Button)`
  width: auto;
  height: auto;

  span {
    font-size: 30px;
  }

  @media screen and (max-width: 768px) {
    span {
      font-size: 25px;
    }
  }
`
