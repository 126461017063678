const initialState = {
  dataHistoryBetting: [],
  error: null
}

export default (state = initialState, action: any) => {
  switch (action.type) {
    case 'FETCH_HISTORY_DETAIL_SUCCESS':
      return {
        ...state,
        dataHistoryBetting: action.payload
      }
    case 'FETCH_HISTORY_DETAIL_FAILURE':
      return {
        ...state,
        error: action.payload
      }
    default:
      return state
  }
}
