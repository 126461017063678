import { mainAxios } from 'libs/axios'

interface DataPramsGetCateProps {
  type: string
}

export const sportCategoryApi = (params: DataPramsGetCateProps) => {
  return mainAxios.request({
    methodType: 'GET',
    url: `/sports/category`,
    params: params,
    requiresToken: true,
    notError: true,
    getError: true,
    config: {
      headers: {
        contentType: 'application/json'
      }
    }
  })
}

interface SportsProps {
  type?: string | number
  limit?: string | number
  page?: string | number
}

export const getHistorySport = (payload: SportsProps) => {
  return mainAxios.request({
    methodType: 'GET',
    url: `/sports/history`,
    params: payload,
    requiresToken: true,
    notError: true,
    getError: true,
    config: {
      headers: {
        contentType: 'application/json'
      }
    }
  })
}

interface DataParamsGetSports {
  type: string
  gamegubun?: string | null
  category?: string | null
}

export const getSports = (params: DataParamsGetSports) => {
  return mainAxios.request({
    methodType: 'GET',
    url: `/sports`,
    params: params,
    requiresToken: true,
    notError: true,
    getError: true,
    config: {
      headers: {
        contentType: 'application/json'
      }
    }
  })
}

interface DataPayloadBettingProps {
  type: string
  room: string | ''
  bettingcnt: number
  bettingdiv: number
  bettingwin: string
  idx_list: string
  pick_list: string
  betDiv_list: string
  gameType_list: string
  gmoney: number
  bet_org_list: string
  betMoney: string
  idx: string[]
  pick: number[]
  betDiv: number[]
  gameType: number[]
  bet_div_list: string[]
}

export const bettingSports = (payload: DataPayloadBettingProps) => {
  return mainAxios.request({
    methodType: 'POST',
    url: `/sports/input`,
    payload: payload,
    requiresToken: true,
    notError: true,
    getError: true,
    config: {
      headers: {
        contentType: 'application/json'
      }
    }
  })
}

interface DataPayloadCancleProps {
  id: number
}

export const cancleBettingSports = (payload: DataPayloadCancleProps) => {
  return mainAxios.request({
    methodType: 'POST',
    url: `/sports/cancel`,
    payload: payload,
    requiresToken: true,
    notError: true,
    getError: true,
    config: {
      headers: {
        contentType: 'application/json'
      }
    }
  })
}
