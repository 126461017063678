import React, { useState, useEffect } from 'react' //lazy
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { Layout } from 'antd'
import { UrlInternal } from 'common/constants/endpoints'
// import { ArrowUpOutlined } from "@ant-design/icons";
// import { slotGametApi } from "apis";

import sg1 from 'assets/images/slot/sg1.png'
import sg2 from 'assets/images/slot/sg2.png'
import sg3 from 'assets/images/slot/sg3.png'
import sg4 from 'assets/images/slot/sg4.png'
import sg5 from 'assets/images/slot/sg5.png'
import sg6 from 'assets/images/slot/sg6.png'
import sg7 from 'assets/images/slot/sg7.png'
import sg8 from 'assets/images/slot/sg8.png'
import sg9 from 'assets/images/slot/sg9.png'
import sg10 from 'assets/images/slot/sg10.png'
import sg11 from 'assets/images/slot/sg11.png'
import sg12 from 'assets/images/slot/sg12.png'
import sg13 from 'assets/images/slot/sg13.png'
import sg14 from 'assets/images/slot/sg14.png'
import sg15 from 'assets/images/slot/sg15.png'
import sg16 from 'assets/images/slot/sg16.png'
import sg17 from 'assets/images/slot/sg17.png'
import sg18 from 'assets/images/slot/sg18.png'
import sg19 from 'assets/images/slot/sg19.png'
import sg20 from 'assets/images/slot/sg20.png'
import sg21 from 'assets/images/slot/sg21.png'
import sg22 from 'assets/images/slot/sg22.png'
import sg23 from 'assets/images/slot/sg23.png'
import sg24 from 'assets/images/slot/sg24.png'
import sg25 from 'assets/images/slot/sg25.png'
import sg26 from 'assets/images/slot/sg26.png'

import * as S from './style'

const { Footer } = Layout

interface Props {
  onClick?: any
  className?: any
}

const data = [
  { id: 0, link: UrlInternal.HOME, img: sg1 },
  { id: 1, link: UrlInternal.HOME, img: sg2 },
  { id: 2, link: UrlInternal.HOME, img: sg3 },
  { id: 3, link: UrlInternal.HOME, img: sg4 },
  { id: 4, link: UrlInternal.HOME, img: sg5 },
  { id: 5, link: UrlInternal.HOME, img: sg6 },
  { id: 6, link: UrlInternal.HOME, img: sg7 },
  { id: 7, link: UrlInternal.HOME, img: sg8 },
  { id: 8, link: UrlInternal.HOME, img: sg9 },
  { id: 9, link: UrlInternal.HOME, img: sg10 },
  { id: 10, link: UrlInternal.HOME, img: sg11 },
  { id: 11, link: UrlInternal.HOME, img: sg12 },
  { id: 12, link: UrlInternal.HOME, img: sg13 },
  { id: 13, link: UrlInternal.HOME, img: sg14 },
  { id: 14, link: UrlInternal.HOME, img: sg15 },
  { id: 15, link: UrlInternal.HOME, img: sg16 },
  { id: 16, link: UrlInternal.HOME, img: sg17 },
  { id: 17, link: UrlInternal.HOME, img: sg18 },
  { id: 18, link: UrlInternal.HOME, img: sg19 },
  { id: 19, link: UrlInternal.HOME, img: sg20 },
  { id: 20, link: UrlInternal.HOME, img: sg21 },
  { id: 21, link: UrlInternal.HOME, img: sg22 },
  { id: 22, link: UrlInternal.HOME, img: sg23 },
  { id: 23, link: UrlInternal.HOME, img: sg24 },
  { id: 24, link: UrlInternal.HOME, img: sg25 },
  { id: 25, link: UrlInternal.HOME, img: sg26 }
]

const ListSlotGame = (props: Props) => {
  const { onClick, className } = props
  const history = useHistory()

  const dataSlot = useSelector((state: any) => state.slotGameList)

  return (
    <S.Wrapper>
      <S.Label>
        슬롯게임 <span>slot game</span>
      </S.Label>
      <S.Content>
        {dataSlot?.slotGameList?.map((v: any, i: number) => (
          <S.Item
            key={i}
            onClick={() =>
              history.push(
                `${UrlInternal.SLOT_DETAIL_ROUTER}type=${v?.type}&code=${v?.code}`
              )
            }
          >
            <img alt='' src={v.icon} />
          </S.Item>
        ))}
      </S.Content>
    </S.Wrapper>
  )
}

export default ListSlotGame
