import styled from 'styled-components'

export const Wrapper = styled.div`
  width: 100%;
  background: #161e2d;
  border: solid 1px rgba(255, 255, 255, 0.04);
  padding: 0px;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  overflow: hidden;
  margin: 10px auto;

  .bestlip {
    font-family: 'SCDream4';
    font-size: 14px;
    font-weight: 700;
    padding: 5px;
    border-bottom: 1px solid #32363a;
    color: #fff;
  }
`

export const InfoBetWrapper = styled.div`
  width: 100%;
  display: flex;
  color: #fff;
`

export const InfoBetColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 33.33%;
  text-align: center;
  .item {
    height: 25px;
    font-size: 12px;
  }
`

export const FormInputWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  padding: 5px;
  flex-wrap: wrap;
`

export const InputWrapper = styled.div`
  width: 100%;
  padding: 4px 29px 5px 7px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 3px;
  padding: 10px 8px;
  background: #d2d8e0;
  &.nBillMoney {
    background: #057575 0% 0% no-repeat padding-box;
  }

  p {
    margin: 0;
    font-size: 14px;
  }

  input,
  span {
    font-size: 18px;
    width: 70%;
    text-align: right;
    background: transparent;
    border: unset;
    outline: none;
  }
`

export const ButtonMoneyWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  height: auto;
  gap: 5px;
  padding-bottom: 5px;
`

export const ButtonSubmit = styled.div`
  width: 100%;
  padding-bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  button {
    width: calc(100% / 2 - 4px);
    border-radius: 4px;
    color: #fff;
    font-size: 15px;
    font-weight: 500;
    padding: 10px 0;
    margin-bottom: 0;
    margin-right: 0;
  }
`
