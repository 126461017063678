import types from '../actions/types'

interface DataType {
  pick_val: string
  bettingdiv: number
  pick_gubun: string
  select_pick: number
}

const defaultState: DataType = {
  pick_val: '',
  bettingdiv: 0,
  pick_gubun: '',
  select_pick: 0
}

export default (state = defaultState, action: any) => {
  switch (action.type) {
    case types.GET_PICK_VALUE:
      return (state.pick_val = action.payload)
    default:
      return state
  }
}
