import React, { memo, useEffect, useState } from 'react'
import Countdown from 'react-countdown'
import { useSelector } from 'react-redux'

function CoundownCustom() {
  const [timeCountdown, setTimeCountdown] = useState<number>(0)
  const [countDownKey, setCountDownKey] = useState(1)
  const { rest_min, rest_sec, delayTime } = useSelector(
    (state: any) => state?.infoMinigame
  )

  const onCompleteTimeFun = () => {
    const delay = (25 + delayTime) * 1000

    setTimeout(() => {
      setCountDownKey((prevKey) => prevKey + 1)
    }, delay)
  }

  const renderer = ({ minutes, seconds, completed }: any) => {
    if (completed) {
      // Render a completed state
      return <span>0:00</span>
    } else {
      // Render a countdown
      return (
        <span>
          {minutes}:{seconds > 9 ? seconds : `0${seconds}`}
        </span>
      )
    }
  }

  useEffect(() => {
    if (!isNaN(rest_min) && !isNaN(rest_sec)) {
      const minus = rest_min * 60
      const seconds = rest_sec
      const time = minus + seconds

      setTimeCountdown(time * 1000)
    } else {
      setTimeCountdown(0)
    }
  }, [rest_min, rest_sec])

  if (timeCountdown === 0) return <span>0:00</span>

  return (
    <Countdown
      key={countDownKey}
      date={Date.now() + timeCountdown}
      renderer={renderer}
      onComplete={onCompleteTimeFun}
    />
  )
}

export default memo(CoundownCustom)
