import styled from 'styled-components'
import theme from 'common/style/styleSetup'
import common from 'assets/images/templates/common.png'

export const Wrapper = styled.div`
  width: max-content;
  height: 26px;
  cursor: pointer;
  border-radius: 3px;
  background: linear-gradient(to top, #022b4c, #033046);
  padding: 2px 7px 0;
  transition: 200ms all;

  font-size: 12px;
  color: #fff;
  display: flex;
  align-items: center;

  :hover {
    background: gold;
    color: #000;
    transition: 200ms all;
  }
`
