import styled from 'styled-components'
import theme from 'common/style/styleSetup'

export const Wrapper = styled.div`
  width: 100%;
  background: #273147;
  padding: 14px 10px;
  border-radius: 5px;
`

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 3px;
`

export const Item = styled.div`
  width: 100%;
  height: 37px;
  border-radius: 5px;
  padding: 0 8px 0 15px;
  border: 1px solid #000;
  cursor: pointer;
  box-shadow:
    rgba(0, 0, 0, 0.35) -3px 7px 6px -6px,
    rgb(92, 94, 108) 1px 1px 4px -2px inset;
  background: ${theme.bg[20]};
  display: flex;
  justify-content: space-between;
  align-items: center;
  :hover {
    background: ${theme.bg[21]};
  }
  .left {
    display: flex;
    align-items: center;
    gap: 8px;
    .label {
      font-size: 12px;
      color: ${theme.color[8]};
    }
  }
  .right {
    width: 50px;
    height: 28px;
    border: 1px solid #3a3a42;
    border-radius: 5px;
    background: #273147;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    color: ${theme.color[8]};
  }
  &.active {
    background: ${theme.bg[21]};
    border: 1px solid #5d8ec7;
    color: ${theme.color[0]};
  }
`
