import React from 'react' //lazy

import * as S from './style'

interface Props {
  onClick?: any
  className?: any
  children?: any
}

const Button3 = (props: Props) => {
  const { onClick, className, children } = props

  return (
    <S.Wrapper className={className} onClick={onClick ?? null}>
      {children}
    </S.Wrapper>
  )
}

export default Button3
