import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import logo from 'assets/images/login/logo.png'
// import logo from 'assets/images/login/World_Logo.png'

import * as S from './style'
interface Props {
  className?: string
}

const PopupCheck = ({ className }: Props) => {
  const dispatch = useDispatch()
  const popupStates = useSelector((state: any) => state.waitting)

  useEffect(() => {
    if (popupStates.isWaitting) {
      setTimeout(() => {
        dispatch({
          type: 'STOP_WAITTING'
        })
      }, 5000)
    }
  }, [popupStates])

  return (
    <S.Wrapper className={popupStates.isWaitting ? 'active' : ''}>
      <div className='bg-loading'>
        <div className='content-loading'>
          <div className='border-loading'></div>
          <div className='icon-loading'>
            <img
              className='icon-loading'
              src={logo}
              alt='loading-logo'
              style={{ width: '100px' }}
            />
          </div>
          {/* <div className='icon-loading'>
            <img
              className='icon-loading'
              src={logo}
              alt='loading-logo'
              style={{ width: '500px' }}
            />
          </div> */}
        </div>
      </div>
    </S.Wrapper>
  )
}

export default PopupCheck
