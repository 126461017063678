import types from '../actions/types'

const defaultState = {
  sportsGameList: []
}

export default (state = defaultState, action: any) => {
  switch (action.type) {
    case types.SPORTS_GAME_LIST:
      return {
        ...state,
        sportsGameList: action.sportsGameList
      }
    default:
      return state
  }
}
