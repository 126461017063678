import styled from 'styled-components'
import theme from 'common/style/styleSetup'
import common from 'assets/images/templates/common.png'

export const Wrapper = styled.div`
  width: 100%;
  background: #161e2d;
  background-color: #161e2d;
  padding: 4px;
`

export const BoxInfo = styled.div`
  width: 100%;
  border-radius: 4px;
  border: 1px solid #303c56;
  .item {
    cursor: pointer;
    width: 100%;
    height: 28px;
    background: #1b2332;
    color: #39c0e3;
    /* border-radius: 2px;
    font-size: 12px;
    font-weight: 600; */
    font-size: 12px;
    font-weight: normal;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .fa {
      margin: 0 3px;
    }
    .icicon {
      background: url(${common}) no-repeat;
      -webkit-background-size: 304px 944px;
      background-size: 304px 944px;
    }
    .money .icicon {
      display: inline-block;
      width: 11px;
      height: 11px;
      margin: 0 3px;
      background-position: -54px -48px;
      vertical-align: top;
    }
    .point .icicon {
      display: inline-block;
      width: 11px;
      height: 11px;
      margin: 0 3px;
      background-position: -69px -48px;
      vertical-align: top;
    }
    &.label {
      color: #fcdc3c;
      padding: 4px 10px 0;
    }
    &.infos {
      border-top: 1px solid #303c56;
      font-size: 11px;
      font-weight: normal;
      padding: 0 6px;
      .value {
        color: #fff;
        margin: 0 4px;
      }
    }
  }
`

export const ButtonBox = styled.div`
  width: 100%;
  height: auto;
  margin-top: 4px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 2px;
  .bt {
    height: 28px;
    border-radius: 2px;
    transition: 0.3s;
    font-size: 12px;
    color: #fff;
    &.pri {
      width: calc(25% - 9px);
      background: #385f8c;
      :hover {
        background: #0e7cf5;
      }
    }
    &.out {
      width: 30px;
      background: #de5936;
      :hover {
        background: #ab2400;
      }
    }
  }
`
