import React, { useState, useEffect } from 'react' //lazy
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { Layout } from 'antd'
import { UrlInternal } from 'common/constants/endpoints'
// import { ArrowUpOutlined } from "@ant-design/icons";

import ic1 from 'assets/images/casino/ic1.png'
import ic2 from 'assets/images/casino/ic2.png'
import ic3 from 'assets/images/casino/ic3.png'
import ic4 from 'assets/images/casino/ic4.png'
import ic5 from 'assets/images/casino/ic5.png'
import ic6 from 'assets/images/casino/ic6.png'
import ic7 from 'assets/images/casino/ic7.png'
import ic8 from 'assets/images/casino/ic8.png'
import ic9 from 'assets/images/casino/ic9.png'
import ic10 from 'assets/images/casino/ic10.png'
import ic11 from 'assets/images/casino/ic11.png'

import * as S from './style'

const { Footer } = Layout

interface Props {
  onClick?: any
  className?: any
}

// const data = [
//   { id: 0, link: UrlInternal.HOME, img: ic1 },
//   { id: 1, link: UrlInternal.HOME, img: ic2 },
//   { id: 2, link: UrlInternal.HOME, img: ic3 },
//   { id: 3, link: UrlInternal.HOME, img: ic4 },
//   { id: 4, link: UrlInternal.HOME, img: ic5 },
//   { id: 5, link: UrlInternal.HOME, img: ic6 },
//   { id: 6, link: UrlInternal.HOME, img: ic7 },
//   { id: 7, link: UrlInternal.HOME, img: ic8 },
//   { id: 8, link: UrlInternal.HOME, img: ic9 },
//   { id: 9, link: UrlInternal.HOME, img: ic10 },
//   { id: 10, link: UrlInternal.HOME, img: ic11 },
// ];

const ListCasino = (props: Props) => {
  const { onClick, className } = props
  const history = useHistory()
  const dataCasino = useSelector((state: any) => state.casinoList)

  return (
    <S.Wrapper>
      <S.Label>
        라이브카지노 <span>live casino</span>
      </S.Label>
      <S.Content>
        {dataCasino?.casinoList?.map((v: any, i: number) => (
          <S.Item
            key={i}
            onClick={() =>
              history.push(
                `${UrlInternal.CASINO_DETAIL_ROUTER}type=${v?.type}&code=${v?.code}`
              )
            }
          >
            <img alt='' src={v.icon} />
          </S.Item>
        ))}
      </S.Content>
    </S.Wrapper>
  )
}

export default ListCasino
