import React, { useEffect, useMemo, useState } from 'react' //lazy

import { Layout } from 'antd'
import { UrlInternal } from 'common/constants/endpoints'
// import { ArrowUpOutlined } from "@ant-design/icons";
import { sportCategoryApi } from 'apis'

import sport1 from 'assets/images/templates/sport1.png'
import sport2 from 'assets/images/templates/sport2.png'
import sport3 from 'assets/images/templates/sport3.png'
import sport4 from 'assets/images/templates/sport4.png'
import sport5 from 'assets/images/templates/sport5.png'
import sport6 from 'assets/images/templates/sport6.png'
import sport7 from 'assets/images/templates/sport7.png'
import sport8 from 'assets/images/templates/sport8.png'

import Item from './item'

import * as S from './style'
import { useHistory, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import types from 'store/actions/types'

const { Footer } = Layout

interface Props {
  onClick?: any
  className?: any
}

const ListSport = (props: Props) => {
  const { onClick, className } = props
  const dispatch = useDispatch()
  const history = useHistory()
  const path = window.location.pathname
  const { sportsGameList } = useSelector((state: any) => state?.sportsGameList)

  const checkPathIsMinigame = useMemo(() => {
    const pathStr = '/' + path.split('/')[1]

    if (pathStr === UrlInternal.MINI_GAME) return true

    return false
  }, [path])

  const getMiniSport = async () => {
    try {
      const params = {
        type: '0'
      }
      const res: any = await sportCategoryApi(params)
      //  console.log(res);
      if (res.result === false) {
        alert(res.message)
      } else {
        dispatch({
          type: types.SPORTS_GAME_LIST,
          sportsGameList: res
        })
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleClick = (e: any, category: string, type: string) => {
    const newUrl = new URLSearchParams(window.location.search)
    newUrl.set('category', category)
    if (path.includes(UrlInternal.SPORT)) {
      history.push(`${window.location.pathname}?${newUrl.toString()}`)
    } else {
      history.push(
        `${UrlInternal.SPORTS_DETAIL_ROUTER}${type}?${newUrl.toString()}`
      )
    }
  }

  useEffect(() => {
    if (!checkPathIsMinigame && !path.includes(UrlInternal.SPORT)) {
      getMiniSport()
    }
  }, [])

  if (checkPathIsMinigame) return <></>

  return (
    <S.Wrapper>
      <S.Label>오늘의 경기</S.Label>
      <S.Content>
        {sportsGameList.map((v: any, i: number) => (
          <div onClick={(e) => handleClick(e, v?.category, v?.type)} key={i}>
            <Item data={v} />
          </div>
        ))}
      </S.Content>
    </S.Wrapper>
  )
}

export default ListSport
