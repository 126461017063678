import React from "react";
import { Button } from "./style";

type Props = {
  children: string
  onClick?: () => void;
  type?: any,
  disabled?: boolean
}

const CustomButton:React.FC<Props> = ({children, onClick, type, disabled}) =>{
  return (
    <Button disabled={disabled} htmlType={type} type={type} onClick={onClick}>
      <span>{children}</span>
    </Button>
  )
}

export default CustomButton
