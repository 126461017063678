import React, { useEffect, useState } from 'react' //lazy
import Countdown from 'react-countdown'

import icon from 'assets/images/templates/listGameIcon.png'

import * as S from './style'

interface Props {
  data?: any
  onClick?: any
  className?: any
} // Random component

const Completionist = () => <span>0:00</span>

const Item = (props: Props) => {
  const { onClick, className, data } = props
  const renderer = ({ hours, minutes, seconds, completed }: any) => {
    if (completed) {
      // Render a completed state
      return <Completionist />
    } else {
      // Render a countdown
      return (
        <span>
          {minutes}:{seconds > 9 ? seconds : `0${seconds}`}
        </span>
      )
    }
  }
  return (
    <S.Item>
      <div className='left'>
        <div className='icon'>
          <img alt='' src={data?.img} />
        </div>
        <div className='label'>{data?.name}</div>
      </div>
      <div className='right'>{data?.count}</div>
    </S.Item>
  )
}

export default Item
