import { mainAxios } from 'libs/axios'

interface ListPaymentProps {
  type?: string
  limit?: string | number
  page?: string | number
}

export const getListPayment = (payload: ListPaymentProps) => {
  return mainAxios.request({
    methodType: 'GET',
    url: `/payment/history`,
    params: payload,
    requiresToken: true,
    notError: true,
    getError: true,
    config: {
      headers: {
        contentType: 'application/json'
      }
    }
  })
}

interface PaymentProps {
  money: number
  mb_exch_pw: string
}

export const getPayment = (payload: PaymentProps) => {
  return mainAxios.request({
    methodType: 'POST',
    url: `/me/payment`,
    payload: payload,
    requiresToken: true,
    notError: true,
    getError: true,
    config: {
      headers: {
        contentType: 'application/json'
      }
    }
  })
}

interface DeletePaymentProps {
  ids: any
}

export const deletePayment = (payload: DeletePaymentProps) => {
  return mainAxios.request({
    methodType: 'POST',
    url: `/me/payment/delete`,
    payload: payload,
    requiresToken: true,
    notError: true,
    getError: true,
    config: {
      headers: {
        contentType: 'application/json'
      }
    }
  })
}
