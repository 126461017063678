import styled from 'styled-components'
import theme from 'common/style/styleSetup'
import common from 'assets/images/templates/common.png'

export const Wrapper = styled.div`
  width: max-content;
  height: 35px;
  cursor: pointer;
  border-radius: 3px;
  background: linear-gradient(to top, #022b4c, #033046);
  padding: 3px 10px 0;
  transition: 200ms all;

  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  color: #fff;

  :hover {
    background: gold;
    color: #000;
    transition: 200ms all;
  }
`
